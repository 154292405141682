import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/line-awesome.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/owl.carousel.css";
import "./assets/css/owl.theme.css";
import "./assets/css/jquery-ui.min.css";
import "./assets/css/jquery.bxslider.css";
import "./assets/css/flexslider.css";
import "./assets/css/settings.css";
import "./assets/css/layers.css";
import "./assets/css/navigation.css";
import "./assets/css/style.css";
import "./App.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { useEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import store from "./store";
import Account from "./screens/Account";
import ScrollToTop from "./ScrollTop";
import Wrapper from "./screens/Welcome/Wrapper";
import { InitAuth, UserData, UserLogin } from "./store/slices/UserSlice";
import AddProduct from "./screens/Products/AddProduct";
import { fetchCategoriesAsync } from "./store/Thunks/CategoryThunks";
import ShowResult from "./screens/Search/ShowResult";
import SetPassword from "./screens/SetPassword";
import Contact from "./screens/Contact/Contact";
import { fetchCartThunk } from "store/Thunks/CartThunks";
import { resetAll } from "store/Thunks/resetSlice";
import { fetchOrderThunk } from "store/Thunks/OrderThunks";
import { fetchProductsAsync } from "store/Thunks/ProductThunks";
// import Error from "pages/Error";
import Home from "screens/Home";
import Cart from "screens/Cart";
import NotFound from "screens/404";
import { validateToken } from "store/Thunks/AuthThunks";
import EditProduct from "screens/Products/EditProduct";
import Product from "screens/Products";
import Orders from "screens/Order";
import Login from "screens/Login";
import SignUp from "screens/SignUp";
import AddCategory from "screens/Category/AddCategory";
import Checkout from "screens/CheckOut";
import ForgetPassword from "screens/ForgetPassword";
import Welcome from "screens/Welcome/Welcome";
import Notification from "screens/Notification";
import SingleProduct from "screens/SingleProduct";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Download from "screens/Download";
import { getDownloadFilesByUsers } from "store/Thunks/DownloadThunks";
import Error from "screens/pages/Error";
import VerificationUser from "screens/Login/verificationUser";

function App(): JSX.Element {
  let isLogin: boolean = useSelector(UserLogin);
  let user = useSelector(UserData);
  const [isError, setIsError] = useState(false);
  const stripePromise = loadStripe(
    "pk_test_51Oj0UzBewB9fi7jpC6ugF499uT0L0WTGXxgqChafymvegad6QDXqZUjUtBMiV6DZ1gCwN8fumQNL9Cs77FbTkPIU00XSUJ5Yj9"
  );

  useEffect(() => {
    if (isLogin) {
      console.log("you enter to cart condition 1");
      store.dispatch(fetchCartThunk());
      store.dispatch(fetchOrderThunk());
      store.dispatch(getDownloadFilesByUsers());
    } else {
      console.log("you enter to cart condition 2");
      store.dispatch(resetAll());
    }
  }, [user, isLogin]);

  useEffect(() => {
    store.dispatch(InitAuth());
    store.dispatch(fetchProductsAsync());
    store.dispatch(fetchCategoriesAsync());
  }, []);

  useEffect(() => {
    if (user != undefined && user.token != undefined && user.token != "") {
      console.log("the data is from user token : " + user.token);
      store.dispatch(validateToken(user.token));
    }
  }, [user]);

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact>
            {/* <Home /> */}
            {isError ? <Error /> : <Home />}
          </Route>
          <Route path={"/orders"}>
            <Orders />
          </Route>
          <Route path={"/checkout"}>
            <Checkout />
          </Route>
          <Route path={"/product/:id"}>
            <SingleProduct />
          </Route>
          <Route path={"/search/:search"}>
            <ShowResult />
          </Route>
          <Route path={"/search"}>
            <ShowResult />
          </Route>
          <Route path={"/account"}>
            {isLogin === true ? <Account /> : <Login />}
          </Route>
          <Route path={"/wishlist"}>
            <Login />
          </Route>
          <Route path={"/verification"}>
            <VerificationUser />
          </Route>
          <Route path={"/downloads"}>
            <Download />
          </Route>
          <Route path={"/addresses"}>
            <Login />
          </Route>
          <Route path={"/cart"}>
            <Cart />
          </Route>
          <Route path={"/notification"}>
            <Notification />
          </Route>
          <Route path={"/register"}>
            <Wrapper />
          </Route>
          <Route path={"/login"}>
            <Login />
          </Route>
          <Route path={"/welcome"}>
            <VerificationUser />
          </Route>
          <Route path={"/signup"}>
            <SignUp />
          </Route>
          <Route path={"/forget-password"}>
            <ForgetPassword />
          </Route>
          <Route path={"/set-password/:email"}>
            <SetPassword />
          </Route>
          <Route path={"/product"}>
            <Product />
          </Route>
          <Route path={"/product-add"}>
            <AddProduct />
          </Route>
          <Route path={"/product-edit/:productId"}>
            <EditProduct />
          </Route>
          <Route path={"/category"}>
            <AddCategory />
          </Route>
          <Route path={"/contact"}>
            <Contact />
          </Route>
          <Route path={"/collection"}>
            <ShowResult />
          </Route>
          <Route path={"*"}>
            <NotFound />
          </Route>
        </Switch>
      </BrowserRouter>
    </Elements>
  );
}

export default App;
