import image4 from "../../../assets/images/Coussin_bebe.png";
import image5 from "../../../assets/images/1 2.png";
import image6 from "../../../assets/images/Sans titre Copier 6.png";
import { MdMiscellaneousServices } from "react-icons/all";
import { BsPrinterFill } from "react-icons/all";
import { BsLightbulb } from "react-icons/all";
// import <BsSmartwatch> from "ic"

const ProductionFeauters = (): JSX.Element => {
  return (
    <section className="services-section4 mb-5">
      <div className="services-box4 container-xxl">
        <div className="services-post4">
          <img src={image4} alt="service1" />
          <div className="serv-content">
            <span className="material-icons-outlined">
              <BsLightbulb size={75} className={"pt-3 mb-4"} />
            </span>
            <h4>Smart Lightning</h4>
            <p>
              Elevate your belongings with our collection of high-quality
              ready-to-print images for transforming your items into something
              truly remarkable
            </p>
          </div>
        </div>
        <div className="services-post4">
          <img src={image5} alt="service2" />
          <div className="serv-content">
            <span className="material-icons-outlined">
              <MdMiscellaneousServices size={75} className={"pt-3 mb-4"} />
            </span>
            <h4>Advanced E-systems</h4>
            <p>
              Explore our captivating stock of scroll-worthy images that will
              inspire and ignite your creativity. With the convenience of easy
              downloading, unlock endless possibilities for your projects.
            </p>
          </div>
        </div>
        <div className="services-post4">
          <img src={image6} alt="service3" />
          <div className="serv-content">
            <span className="material-icons-outlined">
              <BsPrinterFill size={75} className={"pt-3 mb-4"} />
            </span>
            <h4>Best Painting</h4>
            <p>
              Unleash Your Creativity with Daily Updates: Discover the Latest,
              Trendiest Photos, and Designs in Our Dynamic Library.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductionFeauters;
