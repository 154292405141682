import { TextField } from "../../components/atoms/TextField";
import logo from "../../assets/images/logo-image.svg";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  UserData,
  UserError,
  UserLoading,
  UserLogin,
} from "../../store/slices/UserSlice";
import { fetchUser, VerifyUser } from "../../store/Thunks/AuthThunks";
import store from "../../store";
import Footer from "../../components/sections/Footer";
import { Link } from "react-router-dom";
import loadingimg from "../../assets/images/loading-circal.png";

const VerificationUser = (): JSX.Element => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [errorString, setError] = useState("");
  let isLoading: boolean = useSelector(UserLoading);
  let isLogin: boolean = useSelector(UserLogin);
  let error: string = useSelector(UserError);
  let data = useSelector(UserData);
  const [isWaiting, setIsWaiting] = useState(false);
  // const dispatch = useDispatch()

  const handleEmail = (e: any) => {
    setEmail(e);
  };

  const handlePassword = (e: any) => {
    setPassword(e);
  };

  const handleVerificationCode = (e: any) => {
    setCode(e);
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.dispatch(VerifyUser({ email: email, code: code }));
    setIsWaiting(true);
    console.log("you enter to submit handler xx : " + isLoading);
    if (isLoading == false) {
      if (data.user.is_verify) {
        history.push("/login");
      }
    }
    // while (isLogin != true);
    // while (state.isLogin == true);
  };

  useEffect(() => {
    if (isLoading == false) {
      if (data.user.is_verify) {
        history.push("/login");
      }
    }
  }, [isLoading]);

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader />*/}
      <div id="container">
        <div id="">
          <section className="mb-5">
            <div className="container">
              <div className={"contact-form-box d-flex justify-content-center"}>
                <img src={logo} alt="" />
              </div>
              {error != "" && (
                <div className={"bg-danger text-center"}>
                  <h6 className={"text-white py-3"}>{error}</h6>
                </div>
              )}
              <div className="contact-form-box">
                <div className="row g-0">
                  <div className="">
                    <form id="contact-form" onSubmit={onSubmitHandler}>
                      <div className="">
                        {/*<h3> Login </h3>*/}
                        <div className="d-flex flex-column justify-content-center align-items-center h-50">
                          {/*<div className="col-md-6">*/}
                          <h2>Welcome to Sab Print</h2>
                          <h5 className="pt-3">
                            Please enter the verification code we just sent to
                            your email to continue.
                          </h5>
                          <div className="pb-5"></div>
                          {/*</div>*/}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          <TextField
                            id={"email"}
                            name={"email"}
                            placeholder={"Email"}
                            type={"email"}
                            onChange={handleEmail}
                          />
                          <TextField
                            id={"verification_code"}
                            name={"verCode"}
                            placeholder={"Verification code"}
                            type={"number"}
                            onChange={handleVerificationCode}
                          />
                          <br />
                          <br />
                          <br />
                          <button type="submit" id="submit_contact">
                            {isWaiting == true && isLoading == true ? (
                              <img
                                src={loadingimg}
                                alt={"loading-image"}
                                className="imageloop"
                              />
                            ) : (
                              "SEND"
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer isLoading={true} />
    </div>
  );
};

export default VerificationUser;
