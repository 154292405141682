import {createAsyncThunk} from "@reduxjs/toolkit";
import {axiosNew} from "../../services/api";
import { ServerError } from "models/error";
import store from "store";
import { resetAll } from "./resetSlice";



export const validateToken = createAsyncThunk<
    any,
    string,
    {
        rejectValue:ServerError
    }
>(
    'User/validateUser',
    async (customer, { rejectWithValue }): Promise<any> => {
        try{
            const { data } = await axiosNew.post('/auth/validate', {data: {token : customer}})
            return data
        }
        catch(error:any)
        {
            return rejectWithValue({message: error.message, status: error.status})
        }
    }
)

export const fetchUser = createAsyncThunk<
    any,
    Partial<any>,
    {
        rejectValue:ServerError
    }
>(
    'User/fetchuser',
    async (customer, { rejectWithValue }): Promise<any> => {
        try{
            const { data } = await axiosNew.post('/auth/login', {data: customer});
            console.log("the data from  user is: "+ JSON.stringify(data))
            localStorage.setItem("token", JSON.stringify(data));
            return data;
        }
        catch(error:any)
        {
            localStorage.removeItem("token");
            return rejectWithValue({message: error.message, status: error.status});
        }
    }
)

export const addNewUser = createAsyncThunk<
    any,
    Partial<any>,
    {
        rejectValue:ServerError
    }
>(
    'User/addNewUser',
    async (user, { rejectWithValue }): Promise<any> => {
        try{
            // code for testing params 
            // here
            // send to server
            const { data } = await axiosNew.post('/auth/register', {data: user})
            console.log("the value of data: "+ JSON.stringify(data))
            return data
        }
        catch(error:any)
        {
            console.log("the value is from thunks : " + JSON.stringify(error))
            return rejectWithValue({message: error.message, status: error.status})
        }
    }
)


export const VerifyUser = createAsyncThunk<
    any,
    Partial<any>,
    {
        rejectValue:ServerError
    }
>(
    'User/verifyUser',
    async (user, { rejectWithValue }): Promise<any> => {
        try{
            // code for testing params 
            // here
            // send to server
            const { data } = await axiosNew.put('/user/verification', {data: user})
            // console.log("the value of data: "+ JSON.stringify(data))
            return data
        }
        catch(error:any)
        {
            return rejectWithValue({message: error.message, status: error.status})
        }
    }
)

export const updateUser = createAsyncThunk<
    any,
    Partial<any>,
    {
        rejectValue:ServerError
    }
>(
    'User/updateUser',
    async (user, { rejectWithValue }): Promise<any> => {
        try {
            // code for testing params 
            // here
            // send to server
            const { data } = await axiosNew.put('/user', {data: user})
            let value = localStorage.getItem("token")
            let parser
            if (value != undefined){
                parser = JSON.parse(value)
                parser.user = user
            }
            localStorage.setItem("token", JSON.stringify(parser))
            return user
        }
        catch(error:any)
        {
            if (!error)
                throw error
            rejectWithValue({message: error.message, status: error.status})
        }
    }

)




export const logOut = createAsyncThunk<
    any,
    void,
    {
        rejectValue:ServerError
    }
>(
    'User/logOut',
    async (_,{rejectWithValue}): Promise<any> => {
        try{
            // code for testing params 
            // here
            // send to server
            localStorage.removeItem("token")
            const { data } = await axiosNew.post('/auth/logout')
            return data
        }
        catch(error:any)
        {
            if (!error)
                throw error
            rejectWithValue({message: error.message, status: error.status})
        }
    }

)