import image1 from "../../../assets/images/upload/about-grid-1.jpg";
import image2 from "../../../assets/images/upload/about-grid-2.jpg";
import image3 from "../../../assets/images/Site by sab.zip - 22.png";

const WhatWeDo = (): JSX.Element => {
  return (
    <section className="about-section mb-5">
      <div className="container-xl pb-5 mb-5">
        <div className="about-box">
          <div className="row">
            <div className="col-lg-6">
              <div className="images">
                <div className="row">
                  <div className="col-md-6">
                    <img src={image1} alt="about1" />
                    <img src={image2} alt="about2" />
                  </div>
                  <div className="col-md-6">
                    <img className="snd-size" src={image3} alt="about3" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content">
                <h3>Welcome to our website </h3>
                <p>
                  your hub for stunning printable designs. We offer a curated
                  collection of beautifully crafted designs that are ready to be
                  printed.{" "}
                </p>
                <p>
                  Our designs cover a wide range of themes, from nature-inspired
                  patterns to abstract art and everything in between. Each
                  design is meticulously created with attention to detail,
                  ensuring that they make a lasting impression..{" "}
                </p>
                <ul className="feature-list">
                  <li>Artistic</li>
                  <li>Inspiring</li>
                  <li>Captivating</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
