import { TextField } from "../../components/atoms/TextField";
import logo from "../../assets/images/logo-image.svg";
import { useState, useEffect, useReducer } from "react";
import Loader from "../../components/molecules/Loader";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  UserData,
  UserError,
  UserLoading,
  UserLogin,
} from "../../store/slices/UserSlice";
import { fetchUser } from "../../store/Thunks/AuthThunks";
import store from "../../store";
import Footer from "../../components/sections/Footer";
import { Link } from "react-router-dom";
import loadingimg from "../../assets/images/loading-circal.png";

const Login = (): JSX.Element => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorString, setError] = useState("");
  let isLoading: boolean = useSelector(UserLoading);
  let isLogin: boolean = useSelector(UserLogin);
  let error: string = useSelector(UserError);
  let data = useSelector(UserData);
  const [isWaiting, setIsWaiting] = useState(false);
  // const dispatch = useDispatch()

  const handleEmail = (e: any) => {
    setEmail(e);
  };

  const handlePassword = (e: any) => {
    setPassword(e);
  };

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.dispatch(fetchUser({ email: email, password: password }));
    setIsWaiting(true);
    // while (isLogin != true);
    // while (state.isLogin == true);
  };

  useEffect(() => {
    if (isLoading == false) {
      if (isLogin == true && data.user.is_verify == 1) {
        history.push("/");
      } else if (
        isLogin == true &&
        data.user.is_verify === 0 &&
        isLoading == false
      ) {
        history.push("/verification");
      }
    }
  }, [isLoading]);

  return (
    <div id="ip-container" className="ip-container">
      {/*<Loader />*/}
      <div id="container">
        <div id="">
          <section className="mb-5">
            <div className="container">
              <div className={"contact-form-box d-flex justify-content-center"}>
                <img src={logo} alt="" />
              </div>
              {error != "" && (
                <div className={"bg-danger text-center"}>
                  <h6 className={"text-white py-3"}>{error}</h6>
                </div>
              )}
              <div className="contact-form-box">
                <div className="row g-0">
                  <div className="">
                    <form id="contact-form" onSubmit={onSubmitHandler}>
                      <h3> Login </h3>
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          <TextField
                            id={"email"}
                            name={"email"}
                            placeholder={"Email"}
                            type={"email"}
                            onChange={handleEmail}
                          />

                          <TextField
                            id={"password"}
                            name={"password"}
                            placeholder={"Password"}
                            type={"password"}
                            onChange={handlePassword}
                          />

                          <Link to={"/forget-password"} className={"hyperlink"}>
                            Forget Password?
                          </Link>
                          <br />
                          <br />
                          <br />
                          <button type="submit" id="submit_contact">
                            {isWaiting == true && isLoading == true ? (
                              <img
                                src={loadingimg}
                                alt={"loading-image"}
                                className="imageloop"
                              />
                            ) : (
                              "Login"
                            )}
                          </button>
                          <button onClick={() => history.push("/signup")}>
                            Sign Up
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer isLoading={true} />
    </div>
  );
};

export default Login;
