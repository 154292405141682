import { createAsyncThunk } from "@reduxjs/toolkit"
import { ServerError } from "models/error"
import { User } from "models/user"
import { axiosNew } from "services/api"

export const downloadArchive = createAsyncThunk<
any,
{product_id: number, user_id: number},
{
    rejectValue:ServerError
}
>(
    'download/DownloadArchive',
    async (args,{rejectWithValue}): Promise<any> => {
        try {
            // const storage = await localStorage.getItem("token")
            // if (storage == undefined)
                // return rejectWithValue({message: "Please Login", status: 403})
            // let user:User = JSON.parse(storage) as User
            const response  = await axiosNew.get(`/download/archive`, {
                responseType: 'blob',
                params: {
                    product_id: args.product_id,
                    user_id: args.user_id
                }
                // headers: {
                //     "Authorization" : "Basic "+user.token
                // } 
            })
            const blob = new Blob([response.data], { type: 'application/zip' });

            // Create a temporary URL for the blob object
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            const contentDisposition = response.headers['content-disposition'];
            let filename = "archive_"+Math.round(Math.random() * 1000000);
            if (contentDisposition) {
            const matches = contentDisposition.match(/filename="?(.+)"?/);
                if (matches?.[1]) {
                    filename = matches[1];
                }
            }
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            // Initiate the download using window.open
            // window.open(url);
            // window.open("http://localhost:3300/download/"+id);
            // const url = window.URL.createObjectURL(new Blob([data]));
            return null
        }
        catch ( error:any )
        {
            if (!error)
                throw error
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)


export const getDownloadFilesByUsers = createAsyncThunk<
any,
void,
{
    rejectValue:ServerError
}
>(
    'download/downloadFilesByUsers',
    async (_,{rejectWithValue}): Promise<any> => {
        try {
            const storage = await localStorage.getItem("token")
            if (storage == undefined)
                return rejectWithValue({message: "Please Login", status: 403})
            let user:User = JSON.parse(storage) as User
            const {data}  = await axiosNew.get(`/download`, {
                params: {
                    user_id: user.user.id
                }
                // headers: {
                //     "Authorization" : "Basic "+user.token
                // } 
            })
            
            return data
        }
        catch ( error:any )
        {
            if (!error)
                throw error
            return rejectWithValue({message: error["message"], status: error["status"]})
        }
    }
)