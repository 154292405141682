import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {Order, OrderNode} from "../../models/Order";
import {deleteOrder, fetchOrderThunk, postOrder, putOrder} from "../Thunks/OrderThunks";
import {intialOrdersState} from "../intialState";
import {  downloadArchive, getDownloadFilesByUsers } from "store/Thunks/DownloadThunks";
// Orders.order_id , Orders.date_created, SUM(order_details.price) as "price", Products.name, Products.short_description, Products.download_url
type downloadItemType= {
    order_id: number,
    product_id: number,
    date_created: string,
    price: number,
    name: string,
    short_description: string,
    download_url: string,
}

type DownloadType = {
    data: any,
    isLoading: boolean,
    error: string,
}

const intialDownloadsState: DownloadType = {
    data: [],
    isLoading: true,
    error: "",
}


export const DownloadsSlice = createSlice({
    name: "Downloads",
    initialState: intialDownloadsState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDownloadFilesByUsers.pending, (state: DownloadType) => {
                state.isLoading = true
            })
            .addCase(getDownloadFilesByUsers.fulfilled, (state: DownloadType, action: PayloadAction<Array<downloadItemType>>) => {
                state.isLoading = false
                state.data = action.payload
                state.error = ""
            })
            .addCase(getDownloadFilesByUsers.rejected, (state: DownloadType) => {
                state.isLoading = false
                state.error = "Error in fetching data !"
            })
            .addCase(downloadArchive.pending, (state: DownloadType)=> {
                state.isLoading = true
            })
            .addCase(downloadArchive.fulfilled, (state: DownloadType)=>{
                state.isLoading = false
            })
            .addCase(downloadArchive.rejected, (state:DownloadType)=>{
                state.isLoading = false
            })
    }
})


export const DownloadsData = (state: RootState): Array<downloadItemType> =>
    state.downloads.data
export const DownloadsError = (state: RootState): string =>
    state.downloads.error
export const DownloadsLoading = (state: RootState): boolean =>
    state.downloads.isLoading

export default DownloadsSlice.reducer